import React, { useRef, useState, useEffect } from 'react';
import Player from 'react-player';
import { AnimatePresence, useAnimation, motion } from 'framer-motion';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import { NoiseOverlayLight } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import { isMobile } from 'react-device-detect';
import niepodlegla from 'src/assets/images/niepodlegla.svg';
import pr from 'src/assets/images/pr.svg';
import { getLangFromPath } from 'src/utils';
import LanguageSelector from './languageSelector/languageSelector';

import {
  EnterButton,
  Logo,
  Fader,
  LogoChar,
  Subtitle,
  MainWrapper,
  LoaderWrapper,
  IntroWrapper,
  TopBar,
  BottomBar,
  SkipButton,
  LogosWrapper,
} from './styles';

const plmp4 = '/assets/videos/pl_desktop.mp4';
const plwebm = '/assets/videos/pl_desktop.webm';
const enmp4 = '/assets/videos/en_desktop.mp4';
const enwebm = '/assets/videos/en_desktop.webm';
const demp4 = '/assets/videos/de_desktop.mp4';
const dewebm = '/assets/videos/de_desktop.mp4';
const frmp4 = '/assets/videos/fr_desktop.mp4';
const frwebm = '/assets/videos/fr_desktop.mp4';
const rump4 = '/assets/videos/ru_desktop.mp4';
const ruwebm = '/assets/videos/ru_desktop.mp4';
const uamp4 = '/assets/videos/ua_desktop.mp4';
const uawebm = '/assets/videos/ua_desktop.mp4';

const plmobilemp4 = '/assets/videos/pl_mobile.mp4';
const plmobilewebm = '/assets/videos/pl_mobile.webm';
const enmobilemp4 = '/assets/videos/en_mobile.mp4';
const enmobilewebm = '/assets/videos/en_mobile.webm';
const demobilemp4 = '/assets/videos/de_mobile.webm';
const demobilewebm = '/assets/videos/de_mobile.webm';
const frmobilemp4 = '/assets/videos/fr_mobile.webm';
const frmobilewebm = '/assets/videos/fr_mobile.webm';
const rumobilemp4 = '/assets/videos/ru_mobile.webm';
const rumobilewebm = '/assets/videos/ru_mobile.webm';
const uamobilemp4 = '/assets/videos/ua_mobile.webm';
const uamobilewebm = '/assets/videos/ua_mobile.webm';

const localeSettings = {
  pl: {
    url: '/pl/wielka-wojna-zmienia-swiat',
    enterButton: 'Przejdź do serwisu',
    skipButton: 'Pomiń intro',
    videoSource: isMobile ? [plmobilewebm, plmobilemp4] : [plwebm, plmp4],
  },
  de: {
    url: '/de/der-große-krieg-verändert-die-welt',
    enterButton: 'Zur Website Gehen',
    skipButton: 'Intro Überspringen',
    videoSource: isMobile ? [demobilewebm, demobilemp4] : [dewebm, demp4],
  },
  en: {
    url: '/en/the-great-war-changes-the-world',
    enterButton: 'Enter',
    skipButton: 'Skip intro',
    videoSource: isMobile ? [enmobilewebm, enmobilemp4] : [enwebm, enmp4],
  },
  fr: {
    url: '/fr/la-grande-guerre-change-le-monde',
    enterButton: 'Accéder au site',
    skipButton: "Sauter l'intro",
    videoSource: isMobile ? [frmobilewebm, frmobilemp4] : [frwebm, frmp4],
  },
  ru: {
    url: '/ru/Великая-война-меняет-мир',
    enterButton: 'Перейти на сайт',
    skipButton: 'пропустить вступление',
    videoSource: isMobile ? [rumobilewebm, rumobilemp4] : [ruwebm, rump4],
  },
  ua: {
    url: '/ua/велика-війна-змінює-світ/',
    enterButton: 'Перейдіть на сайт',
    skipButton: 'пропустити вступ',
    videoSource: isMobile ? [uamobilewebm, uamobilemp4] : [uawebm, uamp4],
  },
  hu: {
    url: '/hu/a-nagy-haboru-megvaltoztatja-a-vilagot',
    enterButton: 'Menj a weboldalra',
    skipButton: 'Ugrás az intro',
    videoSource: isMobile ? [enmobilewebm, enmobilemp4] : [enwebm, enmp4],
  },
};


const Intro = ({ locale = 'pl' } = {}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [isVisibleSkipButton, setIsVisibleSkipButton] = useState(false);
  const [, dispatch] = useTransitionStore();
  const playerRef = useRef();
  const logoControls = useAnimation();
  const languageSelectorControls = useAnimation();

  const logoOpacityUp = () => {
    logoControls.start({
      opacity: 1,
      zIndex: 2,
      pointerEvents: 'all',
      transition: {
        duration: 2,
        delay: 0.5,
      },
    });
    languageSelectorControls.start({
      opacity: 1,
      zIndex: 5,
      pointerEvents: 'all',
      transition: {
        duration: 2,
      },
    });
  };

  const skipIntro = () => {
    if (!isLoaderVisible) {
      window.suppressAnimation = null;
      dispatch({

        type: 'NAVIGATE',
        to: localeSettings[locale].url,
        leave: {
          opacity: 0,
          config: 'molasses',
        },
      });
    }
  };


  const closeLoader = () => {
    setIsLoaderVisible(false);
    window.suppressAnimation = null;
    if (isMobile) window.scrollTo(0, 1000);

    playerRef.current.seekTo(0);
  };

  const animationComplete = () => {
    logoOpacityUp();
  };

  const handleProgress = ({ played }) => {
    if (played >= 0.9) return skipIntro();
  };

  const subtitle = () => {
    if (getLangFromPath() === 'PL') return 'Bitwa Warszawska';
    return 'Battle of Warsaw';
  };

  return (
    <MainWrapper>
      <AnimatePresence>
        {isLoaderVisible
          && !window.suppressAnimation && (
            <LoaderWrapper
              initial={{ opacity: 1, pointerEvents: 'all' }}
              animate={{ opacity: 1, pointerEvents: 'all' }}
              exit={{ opacity: 0, pointerEvents: 'none' }}
              transition={{ duration: 2, ease: 'easeInOut' }}
            >
              <NoiseOverlayLight />
              <Logo animate={logoControls}>
                <span>
                  <LogoChar
                    initial={{ opacity: 0, transform: 'translate3d(0,3.375rem,0)' }}
                    animate={{ opacity: 1, transform: 'translate3d(0,0rem,0)' }}
                    transition={{ duration: 2.2, delay: 0.04, ease: 'easeInOut' }}
                  >
                    1
             </LogoChar>
                  <LogoChar
                    initial={{ transform: 'translate3d(0,-1.5rem,0)' }}
                    animate={{ transform: 'translate3d(0,0rem,0)' }}
                    transition={{ duration: 1.8, delay: 0.28, ease: 'easeInOut' }}
                  >
                    <Fader
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 2.28, delay: 0.32, ease: 'easeInOut' }}
                    >
                      9

               </Fader>
                  </LogoChar>
                  <LogoChar
                    initial={{ transform: 'translate3d(0,1.81rem,0)' }}
                    animate={{ transform: 'translate3d(0,0rem,0)' }}
                    transition={{ duration: 1.76, delay: 0.04, ease: 'easeInOut' }}
                  >
                    <Fader
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 2.28, delay: 0.56, ease: 'easeInOut' }}
                    >
                      2
               </Fader>
                  </LogoChar>
                  <LogoChar
                    initial={{ opacity: 0, transform: 'translate3d(1.81rem,0,0)' }}
                    animate={{ opacity: 1, transform: 'translate3d(0rem,0,0)' }}
                    transition={{ duration: 2.24, delay: 0.6, ease: 'easeInOut' }}
                  >
                    0
             </LogoChar>
                </span>
                <Fader
                  animate={logoControls}
                  style={{ opacity: 0.5 }}
                >
                  <Subtitle
                    initial={{ opacity: 0, transform: 'translate3d(0,1.5rem,0)' }}
                    animate={{ opacity: 1, transform: 'translate3d(0,0rem,0)' }}
                    transition={{ duration: 2, delay: 1.76, ease: 'easeInOut' }}
                    onAnimationComplete={animationComplete}
                  >
                    {subtitle()}
                  </Subtitle>
                </Fader>
              </Logo>
              <motion.div
                animate={languageSelectorControls}
                style={{ opacity: 0, pointerEvents: 'none' }}
              >

                <LanguageSelector />
              </motion.div>
              <EnterButton
                animate={logoControls}
                onClick={closeLoader}
              >
                {localeSettings[locale].enterButton}
              </EnterButton>
              <LogosWrapper>
                <a href="https://niepodlegla.gov.pl">
                  <motion.img
                    src={niepodlegla}
                    alt="Logo Niepodległa"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 4.5, ease: 'easeInOut' }}
                    style={{ zIndex: 3 }}
                  />
                </a>

                <a href="https://www.polskieradio.pl">
                  <motion.img
                    src={pr}
                    alt="Logo PolskieRadio.pl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 5, ease: 'easeInOut' }}
                    style={{ zIndex: 3 }}

                  />
                </a>
              </LogosWrapper>
            </LoaderWrapper>
          )}
      </AnimatePresence>
      <AnimatePresence>

        {isLoaderVisible && window.suppressAnimation && (
          <LoaderWrapper
            initial={{ opacity: 1, pointerEvents: 'all' }}
            animate={{ opacity: 1, pointerEvents: 'all' }}
            exit={{ opacity: 0, pointerEvents: 'none' }}
            transition={{ duration: 2, ease: 'easeInOut' }}
          >
            <NoiseOverlayLight />
            <Logo style={{ opacity: 1, zIndex: 2 }}>
              <span>
                <LogoChar>
                  1
              </LogoChar>
                <LogoChar>
                  <Fader>
                    9

                </Fader>
                </LogoChar>
                <LogoChar>
                  <Fader>
                    2
                </Fader>
                </LogoChar>
                <LogoChar>
                  0
              </LogoChar>
              </span>
              <Fader>
                <Subtitle style={{ opacity: 1, zIndex: 2 }}>
                  {subtitle()}
                </Subtitle>
              </Fader>
            </Logo>
            <LanguageSelector />
            <EnterButton
              style={{ opacity: 1, pointerEvents: 'all', zIndex: 2 }}
              onClick={closeLoader}
            >
              {localeSettings[locale].enterButton}
            </EnterButton>
            <LogosWrapper>
              <a href="https://niepodlegla.gov.pl">
                <motion.img
                  src={niepodlegla}
                  alt="Logo Niepodległa"
                  style={{ zIndex: 3 }}
                />
              </a>
              <a href="https://www.polskieradio.pl">
                <motion.img
                  src={pr}
                  alt="Logo PolskieRadio.pl"
                  style={{ zIndex: 3 }}
                />
              </a>
            </LogosWrapper>
          </LoaderWrapper>
        )}
      </AnimatePresence>
      <IntroWrapper>
        <Player
          url={localeSettings[locale].videoSource}
          width={isMobile ? 'auto' : '100%'}
          height={isMobile ? '100vh' : 'auto'}
          ref={playerRef}
          playing={!isLoaderVisible}
          progressInterval={10}
          config={{
            file: {
              preload: 'auto',
            },
          }}
          onProgress={handleProgress}
        />

        <AnimatePresence>
          {!isLoaderVisible
            && (
              <>
                <TopBar
                  initial={{ transform: 'translate3d(0,-100%,0)' }}
                  animate={{ transform: 'translate3d(0,0%,0)' }}
                  transition={{ duration: 1, delay: 1, ease: 'easeInOut' }}
                />
                <BottomBar
                  initial={{ transform: 'translate3d(0,100%,0)' }}
                  animate={{ transform: 'translate3d(0,0%,0)' }}
                  transition={{ duration: 1, delay: 1, ease: 'easeInOut' }}
                  onAnimationComplete={() => setIsVisibleSkipButton(true)}
                >
                  <SkipButton
                    onClick={skipIntro}
                    style={{ opacity: isVisibleSkipButton ? 1 : 0, pointerEvents: isVisibleSkipButton ? 'all' : 'none' }}
                  >
                    {localeSettings[locale].skipButton}
                  </SkipButton>
                </BottomBar>
              </>
            )}
        </AnimatePresence>
      </IntroWrapper>
    </MainWrapper>
  );
};

export default Intro;
