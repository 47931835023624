import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  height: 3.2rem;
  position: relative;
  margin-top: 3rem;
  width: 14rem;
  @media (max-width: 768px){
    height: 2.2rem
  }
`;

export const Selector = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  border: 1px solid rgba(245, 241, 236, 0.3);
  border-radius: 2px;
  max-height: 100%;
  width: 100%;
  font-family: "Red Hat Display", "Open Sans";
  cursor: pointer;
  z-index: 4;
  background: #21211f;
  transition: max-height 0.5s cubic-bezier(0.78, 0, 0.235, 1);
  max-height: ${({ open }) => (open ? '100rem' : '3.2rem')};
  @media (max-width: 768px){
    max-height: ${({ open }) => (open ? '100rem' : '2.2rem')};
  }
`;

export const Languages = styled.ul`
  list-style: none;
  z-index: 5;
  position: relative;
`;

export const Flag = styled.div`
  background: url(${({ src }) => src}) content-box;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border: 1px solid #f5f1ec;
  padding: 2px;
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 50%;
  transform: translate3d(0, -55%, 0);
`;

export const Language = styled.li`
  color: white;
  height: 3.2rem;
  align-items: center;
  position: relative;
  text-align: center;
  vertical-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: 768px){
    height: 2.2rem
  }
`;

export const Arrow = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1.5px solid #f5f1ec;
  border-right: 1.5px solid #f5f1ec;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: ${({ rotate }) => (rotate
    ? 'translate3d(0, -50%, 0) rotate(-45deg)'
    : ' translate3d(0, -70%, 0) rotate(135deg)')};
  transition: 0.5s;
`;
