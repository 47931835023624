import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
  <>
    <SEO title="Bitwa Warszawska 1920" lang="pl" description="Jak Polacy uratowali Europę przed komunizmem. Multimedialny przewodnik po 18. decydującej bitwie świata." />
    <Intro locale="pl" />
  </>
);
export default Index;
