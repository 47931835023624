import React, { useState, useEffect } from 'react';
import { useLocation, navigate } from '@reach/router';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import { NoiseOverlayLight } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import pl from 'src/assets/images/_shared/flag_pol.svg';
import hu from 'src/assets/images/_shared/flag_gla.svg';
import ua from 'src/assets/images/_shared/flag_ukr.svg';
import en from 'src/assets/images/_shared/flag_eng.svg';
import fr from 'src/assets/images/_shared/flag_fra.svg';
import de from 'src/assets/images/_shared/flag_ger.svg';
import ru from 'src/assets/images/_shared/flag_rus.svg';
import {
  Wrapper, Selector, Languages, Language, Flag, Arrow,
} from './styles';
import { isMobile } from 'react-device-detect';

const languages = [
  {
    id: 'pl',
    label: 'Polski',
    flag: pl,
  },
  {
    id: 'en',
    label: 'English',
    flag: en,
  },
  {
    id: 'de',
    label: 'Deutsch',
    flag: de,
  },
  {
    id: 'fr',
    label: 'Français',
    flag: fr,
  },
  {
    id: 'ru',
    label: 'Русский',
    flag: ru,
  },
  {
    id: 'ua',
    label: 'Українська',
    flag: ua,
  },
  {
    id: 'hu',
    label: 'Magyar',
    flag: hu,
  },
];

const LanguageSelector = () => {
  const location = useLocation();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    location.pathname.slice(1, 3).toLowerCase(),
  );
  const [, dispatch] = useTransitionStore();

  useEffect(() => {
    localStorage.setItem(
      'language',
      location.pathname.slice(1, 3).toUpperCase(),
    );
  }, []);

  const toggleSelector = () => {
    setIsSelectorOpen(!isSelectorOpen);
  };

  const setLanguage = (id) => {
    window.suppressAnimation = true;
    dispatch({
      type: 'NAVIGATE',
      to: `/${id}`,
      leave: {
        opacity: 0,
      },
    });
  };

  return (
    <Wrapper>
      <Selector
        onClick={toggleSelector}
        open={isSelectorOpen}
      >
        <NoiseOverlayLight />
        <Languages>
          <Language>
            <Flag
              src={
                languages.filter((elem) => elem.id === selectedLanguage)[0].flag
              }
            />
            {languages.filter((elem) => elem.id === selectedLanguage)[0].label}
            <Arrow rotate={isSelectorOpen} />
          </Language>
          {languages
            .filter((elem) => elem.id !== selectedLanguage)
            .map((language) => (
              <Language
                key={language.id}
                onClick={() => setLanguage(language.id)}
              >
                <Flag src={language.flag} />
                {language.label}
              </Language>
            ))}
        </Languages>
      </Selector>
    </Wrapper>
  );
};

export default LanguageSelector;
