import styled from 'styled-components';
import { motion } from 'framer-motion';
import '../../../../../static/fonts/niagara2.css';

export const Logo = styled(motion.h1)`
  font-family: "Niagara Solid";
  color: #f5f1ec;
  font-size: 19.6rem;
  margin: 0;
  opacity: 0.5;
  user-select: none;
  line-height: 0.7;
  letter-spacing: -0.7rem;
  font-weight: 400;
  margin-top: auto;
  padding-top: 5.6rem;
  @media (max-width: 1600px) {
    padding-top: 4.6rem;
  }
`;

export const LogoChar = styled(motion.span)`
  display: inline-block;
`;

export const Fader = styled(motion.span)``;

export const Subtitle = styled(motion.p)`
  color: #f5f1ec;
  font-family: "Oswald";
  font-weight: 500;
  margin: 0%;
  font-size: 2.45rem;
  margin-top: 0.7rem;
  margin-left: 0.3rem;
  opacity: 0.5;
  user-select: none;
  text-align: center;
  letter-spacing: normal;
`;

export const MainWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #21211f;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

export const LoaderWrapper = styled(motion.div)`
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #21211f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  flex-direction: column;
`;

export const IntroWrapper = styled.div`
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #21211f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  @media (max-width: 768px) {
    flex-direction: column;
    /* overflow-y: auto; */
  }
`;

export const BottomBar = styled(motion.div)`
  position: absolute;
  background-color: #21211f;
  height: 8vh;
  width: 100%;
  bottom: 0;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    /* height: 20%; */
    position: fixed;
  }
`;

export const TopBar = styled(motion.div)`
  position: absolute;
  background-color: #21211f;
  height: 8vh;
  width: 100%;
  top: 0;
  left: 0%;
  @media (max-width: 768px) {
    /* height: 5%; */
  }
  
`;

export const SkipButton = styled.button`
  width: 14rem;
  font-family: "Red Hat Display", "Open Sans";
  background: transparent;
  border: 1px solid rgba(245, 241, 236, 0.3);
  border-radius: 2px;
  padding: 0.9rem;

  color: #f5f1ec;
  cursor: pointer;
  transition: opacity 1s;
  @media (max-width: 768px) {
    padding: 0.4rem;
  }
`;

export const EnterButton = styled(motion.button)`
  width: 14rem;
  font-family: "Red Hat Display", "Open Sans";
  background: transparent;
  border: 1px solid rgba(245, 241, 236, 0.3);
  border-radius: 2px;
  padding: 0.9rem;
  color: #f5f1ec;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  margin-top: 1rem;
`;

export const LogosWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  max-width: 300px;
  margin-top: auto;
  z-index: 3;
  margin-bottom: 5.6rem;
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 1rem);
  }
  & img {
    width: 100%;
  }
  & a:first-child {
    margin-right: 2rem;
  }
`;
